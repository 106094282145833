import React, { useEffect, useState } from 'react'
import accountApi from '@/api/accountApi'
import { Table, Button, Modal, Input, message, Popconfirm } from 'antd'
import styles from './home.module.scss'

const otherUser = ['admin11']

const Category: React.FC = () => {
  const username = window.sessionStorage.getItem("username") || ''
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [delLoading, setDelLoading] = useState<boolean>(false)
  
  const [visible, setVisible] = useState<boolean>(false)
  const [active, setActive] = useState<any>({})

  
  const columns = [
    {
      title: '排序',
      dataIndex: 'sort_num',
    },
    {
      title: '组名',
      dataIndex: 'category_name',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (val: string, item: any, index: number) => (
        otherUser.indexOf(username) !== -1 ? (
          <>
            <Button style={{marginRight: 10}} type="primary" size="small" onClick={()=>showModify(item)}>修改</Button>
            <Popconfirm
              title="确定删除 ?"
              onConfirm={() => confirm(item.id, index)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" loading={delLoading} size="small" danger>删除</Button>
            </Popconfirm>
            
          </>
        ): null
        
      ),
    },
  ]

  useEffect(()=>{
    getData()
  }, [])

  const getData = () => {
    setLoading(true)
    accountApi.category().then((result: any)=>{
      setLoading(false)
      if(result.code === 200 && result.data){
        setData(result.data.results)
      }
    })
  }
  const showModify = (item: any) => {
    let temp = JSON.parse(JSON.stringify(item))
    setActive(temp)
    setVisible(true)
  }
  const changeActive = (val: string, type: string) => {
    let temp: any = JSON.parse(JSON.stringify(active))
    temp[type] = val
    setActive(temp)
  }
  const submit = () => {
    let api: any = {}
    setSubmitLoading(true)
    active.sort_num = parseInt(active.sort_num) ? parseInt(active.sort_num) : 0
    if(active.id){
      api = accountApi.setCategory
    }else {
      api = accountApi.createCategory
    }
    api(active).then((result: any)=>{
      setSubmitLoading(false)
      if(result.code === 200 || result.code === 201){
        getData()
        setVisible(false)
        message.success(active.id ? '修改成功！' : '新增成功！')
      }
    })
  }
  const confirm = (id: string, index: number) => {
    let temp = [...data]
    temp[index].loading = true
    setData(temp)
    accountApi.delCategory({id}).then((result: any)=>{
      if(result.code === 200){
        getData()
        message.success('操作成功！')
      }else {
        let temp = [...data]
        temp[index].loading = false
        setData(temp)
      }
    })
  }
  return (
    <>
      <div className={styles.box}>
        {
          otherUser.indexOf(username) !== -1 ? (
            <div className={styles.search}>
              <div className={styles.l}>
                <Button type="primary" onClick={()=>showModify({})}>新增</Button>
              </div>
            </div>
          ): null
        }
        
        <div className={styles.content}>
          <Table dataSource={data} pagination={false} columns={columns} size="small" loading={loading} /> 
        </div>
      </div>
      <Modal
        visible={visible}
        footer={null}
        destroyOnClose
        width={300}
        onCancel={()=>setVisible(false)}
      >
        <div className={styles.modify}>
          <h5>{active.id ? '修改' : '新增'}</h5>
          <div className={styles.item}>
            <label>组名</label>
            <Input value={active.category_name} onChange={(e)=>changeActive(e.target.value, 'category_name')} />
          </div>
          <div className={styles.item}>
            <label>排序</label>
            <Input value={active.sort_num} onChange={(e)=>changeActive(e.target.value, 'sort_num')} />
          </div>
          <Button loading={submitLoading} type="primary" block onClick={()=>submit()}>保存</Button>
        </div>
      </Modal>
    </>
  )
}

export default Category
