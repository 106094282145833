import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Login from '@/page/Login'
import Category, { Company } from '@/page/Home'
import Data, { Datadb, DataAnimation, Chart } from '@/page/Data'

const nav :{[key: string]: any} = {
  "/login": {
    title: ' 登录',
    component: Login
  },
  "/category": {
    title: '类别',
    component: Category
  },
  "/company": {
    title: '公司',
    component: Company
  },
  "/data": {
    title: '数据',
    component: Datadb
  },
  "/dataanimation": {
    title: '数据',
    component: DataAnimation
  },
  "/chart": {
    title: '数据',
    component: Chart
  }
}

function Routes(){
  return (
    <Switch>
      {
        Object.keys(nav).map((key, index)=>{
          let item = nav[key]
          return (<Route exact key={index} path={key} component={item.component} />)
        })
      }
      <Redirect to="/category" />
    </Switch>
  )
}

export default Routes

